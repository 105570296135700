import { DATE_FORMATS } from "../../../constants";
import { client } from "../../../services/axios";
import { LogPreviewItemT } from "../../../types/Mower";
import { formatDate } from "../../../functions/formatDate";
import { createQueryString } from "../../../functions/routing";
import { downloadFile } from "../../../functions/downloadFile";
import { Icon } from "../../../components/atoms/Icon";
import styles from "./mowerFTPLogTab.module.scss";

type PropsT = {
  item: LogPreviewItemT;
  mowerId: number | string;
};

async function downloadLog(mowerId: number | string, name: string) {
  const downloadUrl = `/api/v1/mower/${mowerId}/log/download${createQueryString(
    { folderName: name }
  )}`;
  const fileName = name + ".zip";

  const response = await client.get(downloadUrl, {
    responseType: "arraybuffer",
    headers: { accept: "application/zip" },
  });
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: "application/zip",
    })
  );

  downloadFile(url, fileName);
}

export default function FTPLogItem({ item, mowerId }: PropsT) {
  return (
    <div className={styles.item}>
      <div className={styles.itemMetadata}>
        <div className={styles.itemTitle}>{item.name}</div>
        <div className={styles.itemDetail}>
          <Icon icon="calendar-alt" />
          <span>{formatDate(item.date, DATE_FORMATS.dateTimeMedium)}</span>
        </div>
      </div>
      <Icon
        className={styles.downloadLink}
        onClick={() => downloadLog(mowerId, item.name)}
        icon="download"
      />
    </div>
  );
}

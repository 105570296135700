import { useEffect, useState } from "react";
import classNames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import { ROLE_IDS } from "../../constants";
import { useSelector } from "../../store/hooks";
import { IconsId } from "../../assets/fonts/iconfont/icons";
import { MowerT } from "../../types/Mower";
import { Icon } from "../../components/atoms/Icon";
import MowerInfoTab from "./MowerInfoTab/MowerInfoTab";
import MowerMapTab from "./MowerMapTab/MowerMapTab";
import MowerLogTab from "./MowerLogTab/MowerLogTab";
import styles from "./mowerTabWrapper.module.scss";
import MowerNotificationTab from "./MowerNotificationTab/MowerNotificationTab";
import MowerFTPLogTab from "./MowerFTPLogTab/MowerFTPLogTab";

type TabT = {
  iconId: IconsId;
  styles: string;
  hideOnDesktop: boolean;
  tab: (mower: MowerT) => JSX.Element;
};

function getTabs(isDeveloper: boolean): TabT[] {
  const tabs: TabT[] = [
    {
      iconId: "mower",
      styles: styles.regularTab,
      hideOnDesktop: false,
      tab: (mower: MowerT) => <MowerInfoTab mower={mower} />,
    },
    {
      iconId: "location",
      styles: styles.mapTab,
      hideOnDesktop: true,
      tab: () => <MowerMapTab />,
    },
    {
      iconId: "comment",
      styles: styles.regularTab,
      hideOnDesktop: false,
      tab: (mower) => <MowerNotificationTab mower={mower} />,
    },
  ];
  if (isDeveloper) {
    tabs.push({
      iconId: "list-timeline",
      styles: styles.regularTab,
      hideOnDesktop: false,
      tab: (mower: MowerT) => <MowerLogTab mower={mower} />,
    });
    tabs.push({
      iconId: "bug",
      styles: styles.FTPLogTab,
      hideOnDesktop: false,
      tab: (mower: MowerT) => <MowerFTPLogTab mower={mower} />,
    });
  }

  return tabs;
}

type Props = {
  mower: MowerT;
};

export default function MowerTabWrapper({ mower }: Props) {
  const isCompact = useCompactDesign();

  const { user: currentUser } = useSelector((state) => state.user);

  const tabs = getTabs(currentUser?.role.id === ROLE_IDS.developer);
  const firstVisibleTabIdx =
    tabs.findIndex((tab) => !tab.hideOnDesktop) !== -1
      ? tabs.findIndex((tab) => !tab.hideOnDesktop)
      : 0;
  const [activeTabIdx, setActiveTabIdx] = useState(firstVisibleTabIdx);

  useEffect(() => {
    if (!isCompact && tabs[activeTabIdx].hideOnDesktop) {
      setActiveTabIdx(firstVisibleTabIdx);
    }
  }, [isCompact, activeTabIdx, firstVisibleTabIdx, tabs]);

  const visibleTabs = tabs.filter(
    (tab) => isCompact || !tab.hideOnDesktop
  ).length;

  if (visibleTabs === 0) {
    return null;
  }
  if (!isCompact && visibleTabs === 1) {
    return (
      <TabContent activeTab={firstVisibleTabIdx} className={styles.tabContent}>
        <TabPane
          tabId={firstVisibleTabIdx}
          className={tabs[firstVisibleTabIdx].styles}
        >
          {tabs[firstVisibleTabIdx].tab(mower)}
        </TabPane>
      </TabContent>
    );
  }

  const activeTab = tabs[activeTabIdx];

  return (
    <div className={styles.wrapper}>
      <Nav tabs className={styles.nav}>
        {tabs.map((tab, index) =>
          !isCompact && tab.hideOnDesktop ? null : (
            <NavItem
              key={`${tab.iconId}-${index}`}
              className={styles["nav-item"]}
            >
              <NavLink
                onClick={() => setActiveTabIdx(index)}
                className={classNames(styles["nav-link"], {
                  [styles["nav-link--active"]]: activeTabIdx === index,
                })}
              >
                <Icon icon={tab.iconId} />
              </NavLink>
            </NavItem>
          )
        )}
      </Nav>
      <TabContent activeTab={activeTabIdx} className={styles.tabContent}>
        {
          <TabPane tabId={activeTabIdx} className={activeTab.styles}>
            {activeTab.tab(mower)}
          </TabPane>
        }
      </TabContent>
    </div>
  );
}

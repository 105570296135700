import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { objectReducerGenerator } from "../objectReducerGenerator";
import { storeClear } from "../actions";
import { LatLngT } from "../../types/Route";
import {
  MowerConnectionT,
  MowerStatusT,
  MowerT,
  NearbyMowerT,
} from "../../types/Mower";
import { ConfirmBoxT } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";

export type TrackedMowerT = {
  currentLocation: LatLngT | null;
  mowerTrajectory: LatLngT[];
  nearbyMowers?: NearbyMowerT[];
  mowerStatus?: MowerStatusT;
  mowerData?: MowerT;
  mowerConnection?: MowerConnectionT;
  name: string;
  routeUploaded: boolean;
  routeUploading: boolean;
  isOnline: boolean;
};
export interface MowerState {
  isLoading: boolean;
  mowers: Record<number, TrackedMowerT>;
}

const initialState: MowerState = {
  isLoading: false,
  mowers: {},
};

export const mowerSlice = createSlice({
  name: "mower",
  initialState,
  reducers: {
    ...objectReducerGenerator<MowerState>(),
    setMowerInfoAction: (
      state,
      {
        payload,
      }: PayloadAction<{
        mowerId: number;
        name: string;
        currentLocation: LatLngT;
      }>
    ) => {
      const { mowerId, name, currentLocation } = payload;
      state.mowers[mowerId] = {
        ...state.mowers[mowerId],
        name,
        currentLocation,
      };
    },
    setMowerIsOnlineAction: (
      state,
      { payload }: PayloadAction<{ mowerId: number; isOnline: boolean }>
    ) => {
      const { mowerId, isOnline } = payload;
      if (state.mowers[mowerId] && state.mowers[mowerId].isOnline === isOnline)
        return;
      state.mowers[mowerId] = {
        ...state.mowers[mowerId],
        isOnline,
      };
    },

    setMowerRouteUploadedAction: (
      state,
      { payload }: PayloadAction<{ mowerId: number; routeUploaded: boolean }>
    ) => {
      const { mowerId, routeUploaded } = payload;
      state.mowers[mowerId] = {
        ...state.mowers[mowerId],
        routeUploaded,
      };
    },

    setMowerRouteUploadingAction: (
      state,
      { payload }: PayloadAction<{ mowerId: number; routeUploading: boolean }>
    ) => {
      const { mowerId, routeUploading } = payload;
      state.mowers[mowerId].routeUploading = routeUploading;
    },

    setMowerLocationAction: (
      state,
      {
        payload,
      }: PayloadAction<{
        currentLocation: LatLngT;
        trajectory: LatLngT[];
        mowerId: number;
      }>
    ) => {
      const { currentLocation, trajectory, mowerId } = payload;
      state.mowers[mowerId] = {
        ...state.mowers[mowerId],
        currentLocation,
        mowerTrajectory: [
          ...(state.mowers[mowerId].mowerTrajectory || []),
          ...trajectory,
        ],
      };
    },

    cleanMowerTrajectoryAction: (
      state,
      { payload }: PayloadAction<{ mowerId: number }>
    ) => {
      const { mowerId } = payload;
      state.mowers[mowerId] = {
        ...state.mowers[mowerId],
        mowerTrajectory: [],
      };
    },

    setNearbyMowersAction: (
      state,
      action: PayloadAction<{ nearbyMowers: NearbyMowerT[]; mowerId: number }>
    ) => {
      const { nearbyMowers, mowerId } = action.payload;
      state.mowers[mowerId] = { ...state.mowers[mowerId], nearbyMowers };
    },
    setLoadingAction: (
      state,
      action: PayloadAction<{ isLoading: boolean }>
    ) => {
      state.isLoading = action.payload.isLoading;
    },
    clearMowerAction: (
      state,
      { payload }: PayloadAction<{ mowerIds: number[] }>
    ) => {
      payload.mowerIds.map((mowerId) => {
        delete state.mowers[mowerId];
      });
      state.isLoading = false;
    },
    setMowerStatusAction: (
      state,
      action: PayloadAction<{ mowerStatus: MowerStatusT; mowerId: number }>
    ) => {
      const { mowerStatus, mowerId } = action.payload;
      const ufonPrevState = state.mowers[mowerId]?.mowerStatus?.ufon;
      const mowerStatusWithUfon = {
        ...mowerStatus,
        ufon: mowerStatus.ufon === undefined ? ufonPrevState : mowerStatus.ufon,
      };
      state.mowers[mowerId] = {
        ...state.mowers[mowerId],
        mowerStatus: mowerStatusWithUfon,
      };
    },
    setMowerDataAction: (
      state,
      action: PayloadAction<{ mowerData: MowerT; mowerId: number }>
    ) => {
      const { mowerData, mowerId } = action.payload;
      state.mowers[mowerId] = {
        ...state.mowers[mowerId],
        mowerData: mowerData,
      };
    },
    setMowerConnectionDataAction: (
      state,
      action: PayloadAction<{
        mowerId: number;
        connectionData: MowerConnectionT;
      }>
    ) => {
      const { mowerId, connectionData } = action.payload;
      state.mowers[mowerId] = {
        ...state.mowers[mowerId],
        mowerConnection: connectionData,
      };
    },
    clearMowerStateAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(storeClear, () => initialState);
  },
});

export const startMowerTrackingAction = createAction<{
  mowerId: number;
}>("START_MOWER_TRACKING");
export type StartMowerTrackingActionType = ReturnType<
  typeof startMowerTrackingAction
>;

export const startTrackingNearbyMowersAction = createAction<{
  initialNearbyMowers: NearbyMowerT[];
  deviceId: number;
  deviceType: "ufon" | "mower";
}>("TRACK_NEARBY_MOWERS");
export type StartTrackingNearbyMowersActionType = ReturnType<
  typeof startTrackingNearbyMowersAction
>;

export const stopTrackingNearbyMowersAction = createAction(
  "STOP_NEARBY_MOWERS_TRACKING"
);
export type StopTrackingNearbyMowersActionType = ReturnType<
  typeof stopTrackingNearbyMowersAction
>;

export const autoUploadRouteAction = createAction<{
  jobId: number;
  mowerId: number;
  segmentId?: number;
}>("TRY_AUTO_UPLOAD_ROUTE");
export type AutoUploadRouteActionType = ReturnType<
  typeof autoUploadRouteAction
>;

export const calibrateWheelsAction =
  createAction<{ mowerId: number; confirm: (params: ConfirmBoxT) => void }>(
    "CALIBRATE_WHEELS"
  );
export type CalibrateWheelsActionType = ReturnType<
  typeof calibrateWheelsAction
>;

export const subscribeToMowerConnectionAction = createAction<{
  mowerId: number;
}>("SUBSCRIBE_TO_MOWER_CONNECTION");
export type SubscribeToMowerConnectionActionType = ReturnType<
  typeof subscribeToMowerConnectionAction
>;

export const unsubscribeFromMowerConnectionAction = createAction<{
  mowerId: number;
}>("UNSUBSCRIBE_FROM_MOWER_CONNECTION");
export type UnsubscribeFromMowerConnectionActionType = ReturnType<
  typeof unsubscribeFromMowerConnectionAction
>;

export const {
  setMowerInfoAction,
  setMowerLocationAction,
  setNearbyMowersAction,
  setLoadingAction,
  clearMowerAction,
  setMowerStatusAction,
  setMowerRouteUploadedAction,
  setMowerRouteUploadingAction,
  setMowerIsOnlineAction,
  setMowerDataAction,
  setMowerConnectionDataAction,
  cleanMowerTrajectoryAction,
  clearMowerStateAction,
} = mowerSlice.actions;

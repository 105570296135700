import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ROUTES } from "../../routes";
import { client } from "../../services/axios";
import { GeostickJobT } from "../../types/Job";
import { ApiErrorT } from "../../types/Common";
import { downloadFile } from "../../functions/downloadFile";
import { EditJobModal } from "../../components/molecules/EditJobModal/EditJobModal";
import {
  JobSubheader,
  JobProgressStatusT,
} from "../../components/molecules/JobSubheader/JobSubheader";
import { useBaseActions } from "../../components/molecules/JobSubheader/functions";

type PropsT = {
  title: string;
  status: JobProgressStatusT;
  job: GeostickJobT | undefined;
  isDisabled?: boolean;
  isLoading?: boolean;
  onComplete: VoidFunction;
  onCancel: VoidFunction;
  onArchive: VoidFunction;
  onRestore: VoidFunction;
  onStart: VoidFunction;
  refetchJob: VoidFunction;
};

export const GeostickSubheader: FC<PropsT> = ({
  title,
  status,
  job,
  isDisabled,
  isLoading,
  onComplete,
  onCancel,
  onArchive,
  onRestore,
  onStart,
  refetchJob,
}: PropsT) => {
  const navigate = useNavigate();
  const [editModalData, setEditModalData] = useState<{
    job: GeostickJobT;
    name: string;
  } | null>(null);

  const handleEditJob = () => {
    if (!job) return;
    const editJobLink = `${ROUTES.jobEdit(job.id)}/?type=${job.type.id}`;
    if (status !== "beforeStart") {
      setEditModalData({
        job: job,
        name: job.name,
      });
    } else {
      navigate(editJobLink);
    }
  };

  const handleExport = async () => {
    if (!job) return;
    try {
      const response = await client.get(
        `/api/v1/job/${job.id}/geostick/export`
      );
      if (response.status !== 200) {
        toast.error("Exporting geostick failed");
        return;
      }
      const fileName = `geostick-${job.id}-${job.name}.json`;
      const url = window.URL.createObjectURL(
        new Blob([JSON.stringify(response.data)])
      );
      await downloadFile(url, fileName);

      toast.success("Geostick was exported successfully");
    } catch (e) {
      const { message } = e as ApiErrorT;
      toast.error(`There was an error while exporting geostick: ${message}`);
    }
  };

  const secondaryActions = [
    {
      title: "Edit job",
      action: handleEditJob,
      disabled: isDisabled,
      closeOnClick: true,
    },
    ...useBaseActions(job?.id, job?.type, isDisabled),
  ];

  return (
    <>
      {editModalData && (
        <EditJobModal
          jobId={editModalData.job.id}
          initialName={editModalData.name}
          isOpen={true}
          onClose={() => {
            setEditModalData(null);
          }}
          refetch={refetchJob}
        />
      )}
      <JobSubheader
        title={title}
        status={status}
        isDisabled={!job || !!isDisabled}
        isLoading={!!isLoading}
        isArchived={!!job?.archived}
        onComplete={onComplete}
        onCancel={onCancel}
        onExport={handleExport}
        onArchive={onArchive}
        onRestore={onRestore}
        onStart={onStart}
        secondaryActions={secondaryActions}
      />
    </>
  );
};

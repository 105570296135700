import { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { parse } from "date-fns";
import { ROUTES } from "../../routes";
import {
  DATE_FORMATS,
  JOB_STATUSES,
  ROLE_IDS,
  WINDOW_TITLE_NAMES,
} from "../../constants";
import { GeostickPointsT, MeasurementT } from "../../types/Geostick";
import { GeostickJobT } from "../../types/Job";
import { client } from "../../services/axios";
import {
  setPoints,
  subscribeToGeostickAction,
  unsubscribeFromGeostickAction,
} from "../../store/slices/geostickSlice";
import {
  startUfonTrackingAction,
  stopUfonTrackingAction,
} from "../../store/slices/ufonSlice";
import { useDispatch, useSelector } from "../../store/hooks";
import { useDebounce } from "../../hooks/useDebounce";
import { useSearchParams } from "../../hooks/useSearchParam";
import { useQueryJobGet } from "../../hooks/useQueries";
import { useMutationStartJob } from "../../hooks/useMutationStartJob";
import { useMutationCancelJob } from "../../hooks/useMutationCancelJob";
import { useMutationArchiveJob } from "../../hooks/useMutationArchiveJob";
import { useMutationRestoreJob } from "../../hooks/useMutationRestoreJob";
import { useMutationCompleteJob } from "../../hooks/useMutationCompleteJob";
import { useMutationGeostickMeasurement } from "../../hooks/useMutationGeostickMeasurement";
import { useMutationGeostickEditMeasurement } from "../../hooks/useMutationGeostickEditMeasurement";
import { useMutationGeostickMeasurementDelete } from "../../hooks/useMutationGeostickMeasurementDelete";
import { getJobStatus } from "../../functions/jobStatus";
import { Layout } from "../../components/organisms/Layout/Layout";
import TabsWithMapLayout from "../../components/organisms/TabsWithMapLayout/TabsWithMapLayout";
import { GeostickSubheader } from "./GeostickSubheader";
import { GeostickOverview } from "./GeostickOverview";
import { LogComponent } from "./LogComponent";
import { GeostickStatus } from "./Status/GeostickStatus";
import { MeasurementForm } from "./Status/MeasurementForm";
import { GeostickMap } from "./Map/GeostickMap";
import styles from "./geostick.module.scss";

type PropsT = {};

async function setSettings(
  type: string,
  counter: number,
  jobId?: number | string
) {
  if (jobId !== undefined) {
    await client.put(`api/v1/job/${jobId}/geostick/settings`, {
      type,
      counter,
    });
  }
}

function getLatestMeasurement(measurements: GeostickPointsT) {
  let newest = undefined;
  let newestDate = new Date(0);
  for (const group of measurements) {
    for (const item of group.items) {
      const currentDate = parse(
        item.date,
        DATE_FORMATS.geostickDateTime,
        newestDate
      );
      if (!newest || newestDate < currentDate) {
        newest = item;
        newestDate = currentDate;
      }
    }
  }
  return newest;
}

export const Geostick: FC<PropsT> = () => {
  useEffect(() => {
    document.title = WINDOW_TITLE_NAMES.jobs;
  }, []);
  const { jobId } = useParams() as { jobId: string };
  const {
    data: jobData,
    isLoading,
    refetch: refetchJob,
  } = useQueryJobGet(jobId);
  const job: GeostickJobT | undefined =
    jobData?.data && jobData.data.geostick && jobData.data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateSearchParams } = useSearchParams();
  const { user: currentUser } = useSelector((state) => state.user);
  const switchToStatus = () => {
    updateSearchParams((prev) => {
      return {
        ...prev,
        tab: "0",
      };
    });
  };

  useEffect(() => {
    dispatch(subscribeToGeostickAction({ jobId: Number(jobId) }));
    return () => {
      dispatch(unsubscribeFromGeostickAction({ jobId: Number(jobId) }));
    };
  }, [jobId, dispatch]);
  useEffect(() => {
    if (job) {
      dispatch(setPoints(job.geostick.points));
    }
  }, [job, dispatch]);
  useEffect(() => {
    if (job) {
      dispatch(startUfonTrackingAction({ ufonId: job.geostick.ufon.id }));
      dispatch(startUfonTrackingAction({ ufonId: job.geostick.roverUfon.id }));
    }
    return () => {
      if (job) {
        dispatch(stopUfonTrackingAction({ ufonId: job.geostick.ufon.id }));
        dispatch(stopUfonTrackingAction({ ufonId: job.geostick.roverUfon.id }));
      }
    };
  }, [job, dispatch]);

  const measurements = useSelector((state) => state.geostick.points);

  const lastMeasurementRef = useRef<HTMLDivElement | null>(null);
  const [showOverview, setShowOverview] = useState(true);
  const [measurementName, setMeasurementName] = useState<string | null>("");
  const [measurementNumber, setMeasurementNumber] = useState<number | null>(1);
  const debouncedName = useDebounce(measurementName, 500);
  const debouncedNumber = useDebounce(measurementNumber, 500);
  const [editedItem, setEditedItem] = useState<MeasurementT | null>(null);
  const ufon:
    | { id: number; name: string; latitude?: number; longitude?: number }
    | null
    | undefined = useSelector(
    (state) => state.ufon.ufons[job?.geostick.ufon.id || 0]?.ufon
  );
  const roverUfon:
    | { id: number; name: string; latitude?: number; longitude?: number }
    | null
    | undefined = useSelector(
    (state) => state.ufon.ufons[job?.geostick.roverUfon.id || 0]?.ufon
  );

  const measureMutation = useMutationGeostickMeasurement(jobId);
  const onSubmit = () => {
    if (measurementName === "") {
      toast.error("Please enter a name for the measurement");
      return;
    }
    measureMutation.mutate();
  };

  const measureDeleteMutation = useMutationGeostickMeasurementDelete(jobId);
  const onDelete = (measurement: MeasurementT) => {
    if (editedItem && editedItem.id === measurement.id) {
      setEditedItem(null);
    }
    measureDeleteMutation.mutate(measurement.id);
  };

  const editMeasurementMutation = useMutationGeostickEditMeasurement(jobId, {
    onSuccess: () => toast.success("Measurement edited successfully"),
  });
  const editMeasurement = (
    measurement: MeasurementT,
    newType: string,
    newCounter: number
  ) => {
    editMeasurementMutation.mutate({
      measurementId: measurement.id,
      type: newType,
      counter: newCounter,
    });
  };

  const startJobMutation = useMutationStartJob({
    onSuccess: () => {
      refetchJob();
      setShowOverview(false);
    },
  });

  useEffect(() => {
    if (job) {
      setMeasurementName(job.geostick.type);
      setMeasurementNumber(job.geostick.counter);
    }
  }, [job]);
  useEffect(() => {
    const newest = getLatestMeasurement(measurements);
    if (newest) {
      setMeasurementName(newest.type);
      setMeasurementNumber(newest.counter + 1);
    } else {
      setMeasurementNumber(1);
    }

    if (lastMeasurementRef.current) {
      lastMeasurementRef.current.scrollIntoView();
    }
  }, [measurements]);
  useEffect(() => {
    if (editedItem) {
      setMeasurementName(editedItem.type);
      setMeasurementNumber(editedItem.counter);
    }
  }, [editedItem]);

  useEffect(() => {
    if (
      debouncedName !== "" &&
      debouncedName !== null &&
      debouncedNumber !== null
    ) {
      setSettings(debouncedName.trim(), debouncedNumber, jobId);
    }
  }, [debouncedName, debouncedNumber, jobId]);

  const cancelJobMutation = useMutationCancelJob(jobId, {
    onSuccess: () => {
      refetchJob();
      setShowOverview(true);
    },
  });
  const handleCancelJob = () => {
    cancelJobMutation.mutate();
  };

  const completeJobMutation = useMutationCompleteJob(jobId, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
    },
  });
  const handleCompleteJob = () => {
    completeJobMutation.mutate();
  };

  const archiveJobMutation = useMutationArchiveJob(jobId, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
    },
  });

  const restoreJobMutation = useMutationRestoreJob(jobId, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
    },
  });

  const handleArchiveJob = () => {
    archiveJobMutation.mutateAsync();
  };

  const handleRestoreJob = () => {
    restoreJobMutation.mutateAsync();
  };

  if (!job) {
    return null;
  }

  const userCanHandleJob =
    jobId === `${currentUser?.id}` || // user is the owner
    currentUser?.role.id === ROLE_IDS.developer ||
    currentUser?.role.id === ROLE_IDS.manager; // user is manager of company

  const jobFinished = job && job.status.name === JOB_STATUSES.Finished;

  const status = job ? getJobStatus(job) : "beforeStart";

  return (
    <Layout requireWS>
      <GeostickSubheader
        title={job ? job.name : ""}
        status={status}
        job={job}
        onComplete={handleCompleteJob}
        onCancel={handleCancelJob}
        refetchJob={refetchJob}
        onStart={() => {
          startJobMutation.mutate(jobId);
        }}
        onArchive={handleArchiveJob}
        onRestore={handleRestoreJob}
        isDisabled={!userCanHandleJob}
      />
      <TabsWithMapLayout
        tabName="Status"
        mapComponent={
          <GeostickMap
            ufon={ufon}
            rover={roverUfon}
            measurements={measurements}
            toDelete={jobFinished ? undefined : onDelete}
            toEdit={
              jobFinished
                ? undefined
                : (measurement: MeasurementT) => {
                    setEditedItem(measurement);
                    switchToStatus();
                  }
            }
            isLoading={isLoading}
          />
        }
        logComponent={
          currentUser?.role.id === ROLE_IDS.developer ? (
            <LogComponent
              ufonId={job.geostick.ufon.id}
              roverUfonId={job.geostick.roverUfon.id}
            />
          ) : undefined
        }
        noPadding
      >
        {showOverview ? (
          <GeostickOverview
            job={job}
            status={status}
            continueFromOverview={() => setShowOverview(false)}
          />
        ) : (
          <>
            <GeostickStatus
              ref={lastMeasurementRef}
              className={styles.status}
              measurements={measurements}
              ufon={job && job.geostick.ufon}
              roverUfon={job && job.geostick.roverUfon}
              toDelete={onDelete}
              toEdit={(measurement: MeasurementT) => {
                setEditedItem(measurement);
              }}
              editedItem={editedItem}
              disabled={!userCanHandleJob}
            />
            <MeasurementForm
              className={styles.form}
              name={measurementName || ""}
              number={measurementNumber || 1}
              onNameChange={(name) => {
                const group = measurements.find((m) => m.type === name);
                const last =
                  group && group.items.length > 0
                    ? group.items[group.items.length - 1]
                    : undefined;
                setMeasurementNumber((last?.counter || 0) + 1);
                setMeasurementName(name);
              }}
              onNumberChange={(number) => {
                setMeasurementNumber(number);
              }}
              onEdit={() => {
                if (
                  editedItem &&
                  measurementName &&
                  measurementNumber !== null
                ) {
                  editMeasurement(
                    editedItem,
                    measurementName,
                    measurementNumber
                  );
                  setEditedItem(null);
                }
              }}
              onSubmit={onSubmit}
              onReset={() => {
                const newest = getLatestMeasurement(measurements);
                if (newest) {
                  onDelete(newest);
                }
              }}
              onDelete={() => {
                if (editedItem) {
                  onDelete(editedItem);
                }
              }}
              measurementPossible={true}
              noMeasurements={measurements.length === 0}
              disabled={!userCanHandleJob}
              editing={!!editedItem}
            />
          </>
        )}
      </TabsWithMapLayout>
    </Layout>
  );
};

import { forwardRef, useMemo } from "react";
import classNames from "classnames";
import { parse } from "date-fns";
import { DATE_FORMATS } from "../../../constants";
import {
  GeostickPointsT,
  GeostickUfonT,
  MeasurementT,
} from "../../../types/Geostick";
import { formatDate } from "../../../functions/formatDate";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { UfonStatusCard } from "./UfonStatusCard";
import { MeasurementItem } from "./MeasurementItem";
import styles from "./geostickStatus.module.scss";

type PropsT = {
  measurements: GeostickPointsT;
  ufon?: GeostickUfonT;
  roverUfon?: GeostickUfonT;
  toEdit: (measurement: MeasurementT) => void;
  toDelete: (measurement: MeasurementT) => void;
  editedItem: MeasurementT | null;
  disabled: boolean;
  className?: string;
};

const BottomElement = forwardRef<HTMLDivElement, {}>((_, ref) => (
  <div className={styles.buttomElement} ref={ref} />
));

const sortMeasurementGroups = (groups: GeostickPointsT): MeasurementT[] => {
  const items = groups.flatMap((group) => group.items);
  const today = new Date();
  const itemsDate = items.map((item) => {
    const date = parse(item.date, DATE_FORMATS.geostickDateTime, today);
    return { ...item, date };
  });
  const sorted = itemsDate.sort((a, b) => a.date.getTime() - b.date.getTime());
  return sorted.map((item) => {
    const date = formatDate(item.date, DATE_FORMATS.geostickDateTime);
    return { ...item, date };
  });
};

export const GeostickStatus = forwardRef<HTMLDivElement, PropsT>(
  (
    {
      measurements,
      ufon,
      roverUfon,
      toEdit,
      toDelete,
      editedItem,
      disabled,
      className,
    },
    ref
  ) => {
    const isCompact = useCompactDesign();
    const sortedMeasurements = useMemo(
      () => sortMeasurementGroups(measurements),
      [measurements]
    );

    return (
      <div
        className={classNames(
          styles.status,
          className,
          !isCompact && styles["status-large"]
        )}
      >
        <div className={styles.ufons}>
          <UfonStatusCard
            type="ufon"
            ufonId={ufon?.id || 0}
            ufonName={ufon?.name || "Ufon"}
            className={styles.ufonStatus}
          />
          <UfonStatusCard
            type="geostick"
            ufonId={roverUfon?.id || 0}
            ufonName={roverUfon?.name || "Rover"}
            className={styles.ufonStatus}
          />
        </div>
        {sortedMeasurements.length > 0 && (
          <span className={styles.pointText}>POINT LIST</span>
        )}
        <div
          className={classNames(
            styles.measurementList,
            sortedMeasurements.length === 0 && styles.center
          )}
        >
          {sortedMeasurements.length === 0 && (
            <div className={styles.emptyText}>
              <h4 className={styles["emptyText-header"]}>
                You have no points measured
              </h4>
              <p className={styles["emptyText-body"]}>
                Enter the name of the future point and the first number of the
                measured sequence
              </p>
            </div>
          )}
          {sortedMeasurements
            .map((item) => (
              <MeasurementItem
                key={item.id}
                disabled={disabled}
                measurement={item}
                onDelete={() => toDelete(item)}
                onEdit={() => toEdit(item)}
                highlighted={editedItem?.id === item.id}
              />
            ))
            .concat([<BottomElement key="bottom" ref={ref} />])}
        </div>
      </div>
    );
  }
);

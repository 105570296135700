import { Loader } from "../../../components/atoms/Loader/Loader";
import { useQueryMowerLogList } from "../../../hooks/useQueries";
import { MowerT } from "../../../types/Mower";
import FTPLogItem from "./FTPLogItem";
import styles from "./mowerFTPLogTab.module.scss";

type PropsT = {
  mower: MowerT;
};

export default function MowerFTPLogTab({ mower }: PropsT) {
  const { isLoading, data } = useQueryMowerLogList(mower.id);

  if (isLoading) {
    return <Loader isCentered />;
  }

  return (
    <div className={styles.list}>
      {data?.data.items.map((item, index) => (
        <FTPLogItem
          key={`${item.name}-${index}`}
          item={item}
          mowerId={mower.id}
        />
      ))}
    </div>
  );
}

import { FC } from "react";
import classNames from "classnames";
import { MeasurementT } from "../../../types/Geostick";
import { roundCoordinates } from "../../../functions/unitConversion";
import { StyledButton } from "../../../components/atoms/Button/Button";
import { displayMeasurement } from "../functions";
import { NoFixLabel } from "./NoFixLabel";
import styles from "./measurementItem.module.scss";
import { Trigger } from "./Trigger";

type PropsT = {
  measurement: MeasurementT;
  highlighted?: boolean;
  onEdit: () => void;
  onDelete: () => void;
  disabled: boolean;
};

export const MeasurementItem: FC<PropsT> = ({
  measurement,
  onEdit,
  onDelete,
  highlighted,
  disabled,
}: PropsT) => {
  return (
    <div className={classNames(styles.item, highlighted && styles.highlighted)}>
      <div className={styles.itemHeader}>
        {displayMeasurement(measurement)}
        {(!measurement.fix || measurement.trigger) && (
          <div className={styles.itemSubheader}>
            {!measurement.fix && <NoFixLabel />}
            {measurement.trigger && (
              <Trigger
                className={styles.trigger}
                trigger={measurement.trigger}
              />
            )}
          </div>
        )}
      </div>
      <div className={classNames(styles.metadata, styles.offset)}>
        <div className={styles.datum}>
          <span>
            {`${roundCoordinates(measurement.latitude)} ${roundCoordinates(
              measurement.longitude
            )}`}
          </span>
        </div>
        <div className={classNames(styles.date, styles.datum)}>
          <span>{measurement.date}</span>
        </div>
      </div>
      <StyledButton
        className={styles.btn}
        disabled={disabled}
        onClick={onEdit}
        icon="pen"
        color="link"
      />
      <StyledButton
        className={styles.btn}
        disabled={disabled}
        onClick={onDelete}
        icon="trash"
        color="link"
      />
    </div>
  );
};

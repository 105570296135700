import React from "react";
import { useNavigate } from "react-router";
import { StyledButton } from "../../components/atoms/Button/Button";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { useMutationStopMower } from "../../hooks/useMutationStopMower";
import { usePermissions } from "../../hooks/usePermissions";
import { ROUTES } from "../../routes";
import { useSelector } from "../../store/hooks";

type Props = {
  mowerId: number;
  onDelete: () => void;
};

const MowerSubheader: React.FC<Props> = ({ mowerId, onDelete }) => {
  const permissions = usePermissions();
  const stopMowerMutation = useMutationStopMower();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const mower = useSelector((store) => store.mower.mowers[mowerId]?.mowerData);

  const handleMowerStop = () => {
    confirm({
      title: "Stop mower?",
      asyncCallback: () => stopMowerMutation.mutateAsync(mower?.id || 0),
      type: "ok",
    });
  };

  return (
    <SubHeader
      isDetail
      title={mower?.name || "Loading..."}
      status={mower?.isOnline}
      onGoBack={() => navigate(ROUTES.devices())}
      actions={
        <>
          {mower?.btn.totalStop && (
            <StyledButton
              disabled={stopMowerMutation.isLoading}
              onlyIconOnMobile
              onClick={handleMowerStop}
              color="error"
              icon="stop-circle"
              title="Stop device"
            />
          )}
          {permissions.deviceManage ? (
            <>
              <StyledButton
                icon="pen"
                color="dark"
                outline
                link={ROUTES.mowerEdit(mowerId || 0)}
              />
              <StyledButton
                onClick={onDelete}
                icon="trash"
                color="dark"
                outline
              />
            </>
          ) : undefined}
        </>
      }
    />
  );
};

export default MowerSubheader;

import { useParams } from "react-router";
import { getGeneralData } from "../functions";
import { useSelector } from "../../../store/hooks";
import DeviceDetailStatusRow from "../../../components/molecules/DeviceInfo/DeviceDetailStatusRow";
import CardWithHeader from "../../../components/atoms/CardWithHeader/CardWithHeader";

export default function MowerGeneralInfoCard() {
  const { mowerId } = useParams();
  const mowerData = useSelector(
    (store) => store.mower.mowers[Number(mowerId as string)]?.mowerData
  );
  const deviceData = getGeneralData(mowerData);
  return (
    <CardWithHeader title="Device info">
      {deviceData.map((item, index) => (
        <DeviceDetailStatusRow key={`${item.label}-${index}`} data={item} />
      ))}
    </CardWithHeader>
  );
}
